// @ts-nocheck
const resource = {
  "breadcrumb.back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug"])},
  "breadcrumb.home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "breadcrumb.my-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn account"])},
  "common.title.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout"])},
  "common.title.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
  "common.title.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])},
  "filter.category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorieën"])},
  "form.loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulier wordt geladen..."])},
  "form.validation.accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit veld dient aangevinkt te worden."])},
  "form.validation.confirm-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De wachtwoorden zijn niet gelijk."])},
  "form.validation.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit veld dient een e-mail adres te zijn."])},
  "form.validation.invalid-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze datum is ongeldig."])},
  "form.validation.min-length": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dit veld heeft een minimum lengte van ", _interpolate(_named("MinLength"))])},
  "form.validation.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit veld is verplicht."])},
  "form.validation.vat-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelieve een geldig nummer in te geven, zoals voorbeeld BE0123 456 789"])},
  "invoice.status.open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
  "invoice.status.open.tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Openstaande Factuur"])},
  "invoice.status.paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betaald"])},
  "invoice.status.paid.tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reeds betaald"])},
  "layouts.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spuntini - Totaal leverancier voor horeca"])},
  "product.action.registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login aanvragen"])},
  "product.badge.limited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OP = OP"])},
  "product.badge.new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw"])},
  "product.badge.promo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sale"])},
  "product.message.error.add-to-basket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er deed zich een fout voor bij het toevoegen. Contacteer ons indien het probleem blijft aanhouden."])},
  "product.message.success.add-to-basket": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("product")), " werd toegevoegd aan de winkelwagen."])},
  "product.message.warning.maximum-order-quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het maximum aantal werd bereikt."])},
  "product.message.warning.minimum-order-quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het minimum aantal werd bereikt."])},
  "product.order-product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestelartikel"])},
  "product.stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorraad"])},
  "product.stock.none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uit voorraad"])},
  "search.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoeken naar producten, merken, artikelnr, ..."])},
  "search-suggestions.message.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een fout opgetreden tijdens het zoeken naar suggesties."])},
  "store.label.opening-hours.closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesloten"])},
  "store.label.opening-hours.show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toon openingsuren"])},
  "store.label.route": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Route"])},
  "store.title.contact-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactgegevens"])},
  "store.title.location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Op de kaart"])},
  "store.title.opening-hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Openingsuren"])},
  "webshop.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webshop"])},
  "webshop.label.i-sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iSales"])},
  "webshop.label.roeselare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webshop klanten Roeselare"])}
}
export default resource